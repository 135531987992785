import { InferType, q, z, nullToUndefined } from "groqd";
import { HeroTwoColSelection } from "@/components/PageBuilder/heroTwoColumn";
import { ctaSelection } from "@/components/CTABlock";
import { singleImageAccordionSelection } from "@/components/SingleImageAccordion";
import { carouselSelection } from "@/components/Carousel";
import { multiColumnSectionSelection } from "@/components/MultiColumnSection";
import { slidingTestimonialsCarouselSelection } from "@/components/SlidingTestimonialsCarousel";
import { FAQAccordionSelection } from "@/components/FAQAccordion";
import { videoHighlightSelection } from "@/components/VideoHighlight";
import { videoGridSelection } from "@/components/VideoGrid";
import { featuredArticlesSelection } from "@/components/FeaturedArticles";
import { logosBannerSelection } from "@/components/LogosBanner";
import { meetFamiliesSelection } from "@/components/MeetFamilies";
import { ctaGridSelection } from "@/components/CTAGrid";
import { ctaBannerSelection } from "@/components/CTABanner";
import { heroWithFormSelection } from "@/components/HeroWithForm";
import { PageProps } from "@/types/pageProps";
import { Previewable } from "@/types/previewable";
import { knowledgeIsPowerSelection } from "@/selections/knowledge-is-power-selection";
import { MeetProvidersSelection } from "@/selections/meet-providers-selection";
import { HeaderNavigationProps } from "@/components/HeaderNavigation/query";
import { heroWithButtonsSelection } from "@/components/HeroWithButtons";
import { pricingPlansSelection } from "@/components/PricingPlans";
import { FooterNavigationProps } from "@/components/FooterNavigation/query";

export const modularRefPageQuery = q("*")
  .filter("_type == 'modularRefPage' && slug.current == $slug")
  .grab(
    nullToUndefined({
      _id: z.nullable(q.string().optional()),
      slug: q.slug("slug"),
      colorway: z.nullable(q.string().optional()),
      title: z.nullable(q.string().optional()),
      seo: nullToUndefined(
        q.object({
          title: z.nullable(q.string().optional()),
          description: z.nullable(q.string().optional()),
          noIndex: z.nullable(q.boolean().optional()),
        }),
      ),
      sections: q("sections[]")
        .filter()
        .select({
          "_type == 'reference'": q("@")
            .deref()
            .grab(
              {
                _type: q.string(),
                _id: q.string(),
                key: ["_id", q.string()],
              },
              {
                '_type == "CTABlocks"': {
                  ["CTABlocksGroup"]: q.object(ctaSelection).array(),
                },
                '_type == "MultiColumnSection"': multiColumnSectionSelection,
                '_type == "SingleImageAccordion"':
                  singleImageAccordionSelection,
                '_type == "Carousel"': carouselSelection,
                '_type == "SlidingTestimonialsCarousel"':
                  slidingTestimonialsCarouselSelection,
                '_type == "FAQAccordion"': FAQAccordionSelection,
                '_type == "VideoHighlight"': videoHighlightSelection,
                '_type == "VideoGrid"': videoGridSelection,
                '_type == "FeaturedArticles"': featuredArticlesSelection,
                '_type == "LogosBanner"': logosBannerSelection,
                '_type == "MeetFamilies"': meetFamiliesSelection,
                '_type == "CTAGrid"': ctaGridSelection,
                '_type == "CTABanner"': ctaBannerSelection,
                '_type == "heroTwoColumn"': HeroTwoColSelection,
                '_type == "HeroWithForm"': heroWithFormSelection,
                '_type == "HeroWithButtons"': heroWithButtonsSelection,
                '_type == "PricingPlans"': pricingPlansSelection,
              },
            ),
          "_type != 'reference'": q("@").grab(
            {
              _type: q.string(),
              _key: z.nullable(q.string().optional()),
              key: ["_key", q.string()],
            },
            {
              '_type == "knowledgeIsPowerModule"': knowledgeIsPowerSelection,
              '_type == "meetProvidersModule"': MeetProvidersSelection,
            },
          ),
        }),
    }),
  )
  .slice(0);

const pageRefsSlugsQueryString = `
  *[_type == 'modularRefPage' && defined(slug.current)].slug.current
  `;

export const pageRefsSlugsQuery = q(pageRefsSlugsQueryString, {
  isArray: true,
});

export type ModularRefPageData = InferType<typeof modularRefPageQuery>;

export type ModularRefPageProps = Previewable<
  PageProps<
    ModularRefPageData &
      HeaderNavigationProps & { footerData: FooterNavigationProps }
  >
>;
