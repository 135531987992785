import { InferType, q, z } from "groqd";
import { imageSelection } from "@/selections/image-selection";
import { ctaButtonSelection } from "../CtaButton/selection";

export const linkSelection = {
  _key: z.nullable(q.string().optional()),
  _type: q.string(),
  linkType: q.string(),
  text: z.nullable(q.string().optional()),
};

export const phoneLinkSelection = {
  ...linkSelection,
  phoneLink: q.string(),
};

export const modularPageLinkSelection = {
  ...linkSelection,
  modularPageLink: q("modularPageLink")
    .deref()
    .grab({
      _id: q.string(),
      slug: q.slug("slug"),
    }),
};

export const customPageLinkSelection = {
  ...linkSelection,
  otherPageLink: z.nullable(q.string().optional()),
};

export const navSectionSelection = {
  _id: q.string(),
  _type: q.string(),
  colorway: z.nullable(q.string().optional()),
  navType: z.nullable(q.string().optional()),
  singleNavItem: q("singleNavItem")
    .grab(
      { _type: z.nullable(q.string().optional()) },
      {
        "linkType == 'Phone Number'": phoneLinkSelection,
        "linkType == 'Modular Page'": modularPageLinkSelection,
        "linkType == 'Custom Page'": customPageLinkSelection,
      },
    )
    .nullable(),
  sectionLinkText: z.nullable(q.string().optional()),
  header: z.nullable(q.string().optional()),
  headingCta: q.object(ctaButtonSelection),
  icon: z.nullable(q.object(imageSelection)),
  footerCtaPrimary: z.nullable(q.object(ctaButtonSelection)),
  footerCtaSecondary: z.nullable(q.object(ctaButtonSelection)),
  navSectionLinks: q("navSectionLinks[]")
    .filter()
    .select({
      "linkType == 'Phone Number'": phoneLinkSelection,
      "linkType == 'Modular Page'": modularPageLinkSelection,
      "linkType == 'Custom Page'": customPageLinkSelection,
    })
    .nullable(),
  highlightablePaths: z.nullable(q.array(q.string().optional())),
};

export const HeaderNavigationSelection = {
  navSections: q("navSections")
    .filter()
    .deref()
    .grab({
      ...navSectionSelection,
    }),
};

export const HeaderNavigationQuery = q("*")
  .filter("_type == 'headerNavigation'")
  .slice(0)
  .grab(HeaderNavigationSelection);

export type HeaderNavigationProps = InferType<typeof HeaderNavigationQuery>;
