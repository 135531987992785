import { isEmpty } from "@/utils/isEmpty";
import QuickLinks from "../QuickLinks/QuickLinks";
import Block from "../block";
import { HeroWithButtonsProps } from "./types";
import { Colorways } from "src/theme";
import Link from "next/link";
import Image from "next/image";
import { getNavItemUrl } from "../HeaderNavigation/getNavItemUrl";
import { getImageURL } from "@/utils/getImageURL";

export default function HeroWithButtons({
  colorway,
  heading,
  copy,
  buttons,
  quickLinks,
}: HeroWithButtonsProps & Colorways) {
  return (
    <>
      <Block fullWidth className={`bg-home-gradient relative text-white`}>
        <div className="container mx-auto">
          <div className="relative z-10 mx-auto px-8 py-12 text-center lg:w-3/4 lg:px-2">
            <div>
              <h1 className="text-deco-head-3 lg:text-deco-head-1 lg:text-deco-head-1-lg">
                {heading}
              </h1>
              <p className="text-body-1 mx-auto mt-14 text-pretty lg:w-5/6">
                {copy}
              </p>
            </div>
            <div className="mx-auto mt-12 flex flex-col items-center justify-center gap-6 lg:flex-row ">
              {buttons?.map((button) => {
                const link = getNavItemUrl(button);

                return (
                  <Link href={link} key={button._key}>
                    <span className="text-head-7 rollover-animation flex items-center justify-center gap-1 rounded-full border-2 border-white py-1 pl-1 pr-4 text-white">
                      {!!button.icon && !!button.icon.asset && (
                        <span className="rounded-full bg-white/25 p-2">
                          <Image
                            src={getImageURL(button.icon).url()}
                            width={32}
                            height={32}
                            alt={button.text ?? ""}
                            className="object-inherit m-auto flex items-center justify-center"
                          />
                        </span>
                      )}
                      <span className="inline-block text-pretty px-4 font-semibold">
                        {button.text}
                      </span>
                    </span>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 z-0">
            <Image
              src="/hero-vector-overlay.png"
              width={0}
              height={0}
              style={{ width: "100%" }}
              className={`flex w-full object-cover`}
              alt="hero"
            />
          </div>
        </div>
      </Block>

      {!isEmpty(quickLinks) && quickLinks?.config?.showQuickLinks && (
        <QuickLinks {...quickLinks} colorway={colorway} />
      )}
    </>
  );
}
