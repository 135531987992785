import { q, sanityImage, z } from "groqd";
import { imageSelection } from "./image-selection";

export const MeetProvidersSelection = {
  _type: q.string(),
  _key: q.string(),
  meetProviders: q.object({
    availableState: q.object({
      notReady: q.object({
        ctas: q.array(
          q.object({
            text: z.nullable(q.string().optional()),
            url: z.nullable(q.string().optional()),
          }),
        ),
        description: z.nullable(q.string().optional()),
        title: z.nullable(q.string().optional()),
      }),
      startCard: q.object({
        cta: q.object({
          text: z.nullable(q.string().optional()),
        }),

        description: z.nullable(q.string().optional()),
        title: z.nullable(q.string().optional()),
        subtitle: z.nullable(q.string().optional()),
      }),
    }),
    emptyState: q.object({
      image: q.object(imageSelection),
      text: z.nullable(q.string().optional()),
      notReady: q.object({
        ctas: q.array(
          q.object({
            text: z.nullable(q.string().optional()),
            url: z.nullable(q.string().optional()),
          }),
        ),
        description: z.nullable(q.string().optional()),
        title: z.nullable(q.string().optional()),
      }),
    }),
    heading: z.nullable(q.string().optional()),
    stateSelectionText: z.nullable(q.string().optional()),
    unavailableState: q.object({
      image: q.object(imageSelection),
      learnMore: q.object({
        ctas: q.array(
          q.object({
            text: z.nullable(q.string().optional()),
            url: z.nullable(q.string().optional()),
          }),
        ),
        description: z.nullable(q.string().optional()),
        title: z.nullable(q.string().optional()),
      }),
      text: z.nullable(q.string().optional()),
      title: z.nullable(q.string().optional()),
    }),
  }),
  providers: q("*")
    .filterByType("provider")
    .order("_updatedAt desc")
    .grab({
      person: q("person")
        .deref()
        .grab({
          _id: q.string(),
          _type: q.string(),
          image: sanityImage("image"),
          name: z.nullable(q.string().optional()),
        }),
      _id: q.string(),
      bio: z.nullable(q.string().optional()),
      providerType: z.nullable(q.string().optional()),
      services: z.nullable(q.array(q.string())),
      contactInfo: q("contactInfo")
        .filter()
        .grab({
          state: q("state").deref().grab({ code: q.string() }),
          email: z.nullable(q.string().optional()),
          zoom: z.nullable(q.string().optional()),
          formLink: z.nullable(q.string().optional()),
        }),
    }),
  states: q("*")
    .filterByType("state")
    .grab({
      name: z.nullable(q.string().optional()),
      funFact: z.nullable(q.string().optional()),
      image: q.object(imageSelection),
      description: z.nullable(q.string().optional()),
      code: z.nullable(q.string().optional()),
      postAdoptionAgreement: z.nullable(q.string().optional()),
    }),
  stateSelector: q("*")
    .filterByType("stateSelection")
    .slice(0)
    .grab({
      image: q.object(imageSelection),
    }),
};
