import Block from "./block";
import Link from "next/link";
import { FooterNavigationProps } from "./FooterNavigation/query";
import Image from "next/image";
import { getImageURL } from "@/utils/getImageURL";
import { getNavItemUrl } from "./HeaderNavigation/getNavItemUrl";

function FooterLink(props: any) {
  const linkUrl = getNavItemUrl(props);

  return (
    <Link
      href={linkUrl ?? "#"}
      className="my-3 block text-body-13 font-medium text-onyx-light hover:text-teal"
    >
      <span>{props?.text}</span>
    </Link>
  );
}

export function FooterModular({
  footerData,
}: {
  footerData: FooterNavigationProps;
}) {
  return (
    <Block flushTop flushBottom>
      <div className="mt-10 flex columns-2 flex-col justify-between gap-8 border-y-4 border-platinum md:flex-row">
        <div className="mx-auto flex flex-col justify-center pt-9 text-center lg:w-1/3 lg:pt-0">
          <span className="text-head-6 font-bold text-onyx">
            We&apos;re here for you.
          </span>
          <Link href="mailto:hello@pairtreefamily.com">
            <span className="text-head-6 text-green">
              hello@pairtreefamily.com
            </span>
          </Link>

          <div className="mx-auto mb-8 mt-4 flex flex-row gap-2">
            {footerData &&
              footerData.socialMediaLinks?.map((link) => {
                if (link?.urlImage && link?.profileUrl) {
                  return (
                    <Link
                      className="rollover-animation flex items-center justify-center rounded-full bg-green p-4"
                      key={link._id}
                      href={link?.profileUrl ?? "#"}
                    >
                      <Image
                        className="m-auto object-none"
                        src={getImageURL(link.urlImage).url()}
                        alt="social media icon"
                        width={30}
                        height={30}
                      />
                    </Link>
                  );
                }
              })}
          </div>
        </div>

        <div className="lg:w-2/3">
          {footerData && (
            <ul className="grid grid-cols-1 gap-8 py-16 sm:grid-cols-2 md:grid-cols-4">
              <li>
                {footerData.navSectionsColumn1.map((navSection) => (
                  <div key={navSection._id} className="mb-8">
                    <span className="block text-body-13 font-bold text-black">
                      {navSection.header}
                    </span>
                    {navSection?.navSectionLinks?.map((navLink) => (
                      <FooterLink key={navLink._key} {...navLink} />
                    ))}
                  </div>
                ))}
              </li>
              <li>
                {footerData.navSectionsColumn2.map((navSection) => (
                  <div key={navSection._id}>
                    <span className="block text-body-13 font-bold text-black">
                      {navSection.header}
                    </span>
                    {navSection?.navSectionLinks?.map((navLink) => (
                      <FooterLink key={navLink._key} {...navLink} />
                    ))}
                  </div>
                ))}
              </li>
              <li>
                {footerData.navSectionsColumn3.map((navSection) => (
                  <div key={navSection._id}>
                    <span className="block text-body-13 font-bold text-black">
                      {navSection.header}
                    </span>
                    {navSection?.navSectionLinks?.map((navLink) => (
                      <FooterLink key={navLink._key} {...navLink} />
                    ))}
                  </div>
                ))}
              </li>
              <li>
                {footerData.navSectionsColumn4.map((navSection) => (
                  <div key={navSection._id}>
                    <span className="block text-body-13 font-bold text-black">
                      {navSection.header}
                    </span>
                    {navSection?.navSectionLinks?.map((navLink) => (
                      <FooterLink key={navLink._key} {...navLink} />
                    ))}
                  </div>
                ))}
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="container mx-auto">
        <p className="py-4 text-body-13">&copy; 2024 PairTree.</p>
      </div>
    </Block>
  );
}

export default FooterModular;
