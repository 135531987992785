import { q } from "groqd";
import { sanityInternalsSelection } from "@/selections/sanity-internals-selection";
import { quickLinksSelection } from "../QuickLinks";
import { imageSelection } from "@/selections/image-selection";
import {
  customPageLinkSelection,
  modularPageLinkSelection,
} from "../HeaderNavigation/query";

export const heroWithButtonsSelection = {
  ...sanityInternalsSelection,
  heading: q.string(),
  copy: q.string(),
  buttons: q("buttons[]")
    .filter()
    .grab(
      {
        _key: q.string(),
        _type: q.string(),
        icon: q.object(imageSelection),
        linkType: q.string(),
        text: q.string(),
      },
      {
        "linkType == 'Modular Page'": modularPageLinkSelection,
        "linkType == 'Custom Page'": customPageLinkSelection,
      },
    ),
  quickLinks: q.object(quickLinksSelection),
};
