import { SEOPageData } from "@/types/seoPageData";
import Head from "next/head";

export default function SEOPageHead(props: SEOPageData) {
  const { title, description, noIndex } = props.seo;
  return (
    <Head>
      {!!title && <title>{`${title} • PairTree`}</title>}
      {!!description && <meta name="description" content={description} />}
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
}
