import { q, z } from "groqd";
import { imageSelection } from "./image-selection";

export const knowledgeIsPowerSelection = {
  _type: q.string(),
  _key: q.string(),
  knowledgeIsPower: q.object({
    subtitle: z.nullable(q.string().optional()),
    title: z.nullable(q.string().optional()),
    selectHeading: z.nullable(q.string().optional()),
    cta: q.object({
      ctaText: z.nullable(q.string().optional()),
      heading: z.nullable(q.string().optional()),
      url: z.nullable(q.string().optional()),
      subtitle: z.nullable(q.string().optional()),
    }),
    postAdoptionAgreementSection: z.nullable(
      q
        .object({
          accordionContentText: z.nullable(q.string().optional()),
          accordionContentTitle: z.nullable(q.string().optional()),
          primaryHeader: z.nullable(q.string().optional()),
          secondaryHeader: z.nullable(q.string().optional()),
        })
        .optional(),
    ),
  }),
  states: q("*")
    .filterByType("state")
    .grab({
      name: z.nullable(q.string().optional()),
      funFact: z.nullable(q.string().optional()),
      image: z.nullable(q.object(imageSelection).optional()),
      description: z.nullable(q.string().optional()),
      code: z.nullable(q.string().optional()),
      postAdoptionAgreement: z.nullable(q.string().optional()),
    }),
};
