import type { GetStaticProps, NextPage } from "next";
import { revalidationInterval } from "@/utils/revalidationInterval";
import PageBuilder from "@/components/PageBuilder";
import {
  ModularRefPageProps,
  modularRefPageQuery,
  pageRefsSlugsQuery,
} from "@/queries/modularRefPageQuery";
import { LiveQuery } from "@sanity/preview-kit/live-query";
import {
  HeaderNavigationProps,
  HeaderNavigationQuery,
} from "@/components/HeaderNavigation/query";
import HeaderNavigation from "@/components/HeaderNavigation/HeaderNavigation";
import SEOPageHead from "@/components/SEOPageHead";
import { getClient } from "@/lib/sanity.server";
import FooterModular from "@/components/FooterModular";
import {
  FooterNavigationProps,
  FooterNavigationQuery,
} from "@/components/FooterNavigation/query";

const token = process.env.SANITY_API_TOKEN;

export const ModularRefPage: NextPage<ModularRefPageProps> = (props) => {
  const { data } = props.payload;

  const modQuery = modularRefPageQuery.query;

  return (
    <LiveQuery
      enabled={props.preview}
      query={modQuery}
      params={{ slug: data.slug }}
      initialData={data}
    >
      <>
        {data.seo && <SEOPageHead seo={data.seo} />}
        <HeaderNavigation navSections={data.navSections} />
        <div className="mt-20">
          <PageBuilder props={data} />
        </div>
        <FooterModular footerData={data.footerData} />
      </>
    </LiveQuery>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const preview = !!context.draftMode;

  const slugArray = context?.params?.slug;

  const modularPayload = await getClient(preview).fetch(
    modularRefPageQuery.query,
    {
      slug: `/${Array.isArray(slugArray) ? slugArray.join("/") : slugArray}`,
      preview: preview,
    },
  );

  if (!modularPayload) {
    return {
      notFound: true,
    };
  }

  const navPayload: HeaderNavigationProps = await getClient(preview).fetch(
    HeaderNavigationQuery.query,
    {},
  );

  const footerData: FooterNavigationProps = await getClient(preview).fetch(
    FooterNavigationQuery.query,
    {},
  );

  const payload = {
    data: {
      ...modularPayload,
      ...navPayload,
      footerData: footerData,
    },
  };

  return {
    props: {
      payload: payload,
      preview,
      token: preview ? token : "",
    },
    revalidate: revalidationInterval,
  };
};

export async function getStaticPaths() {
  const allPages = await getClient(false).fetch(pageRefsSlugsQuery.query, {});

  return {
    paths:
      allPages?.map((slug: any) => {
        const slugs = (slug as string).split("/").filter(Boolean);

        return {
          params: {
            slug: slugs,
          },
        };
      }) || [],
    fallback: false,
  };
}

export default ModularRefPage;
