import React from "react";
import dynamic from "next/dynamic";
import { ModularRefPageData } from "@/queries/modularRefPageQuery";

type SectionModules = {
  [key: string]: React.ComponentType<any>;
};

const SECTIONS: SectionModules = {
  Carousel: dynamic(() => import("../Carousel/Carousel")),
  CTABlocks: dynamic(() => import("../CTABlock/CTABlock")),
  SingleImageAccordion: dynamic(
    () => import("../SingleImageAccordion/SingleImageAccordion"),
  ),
  MultiColumnSection: dynamic(
    () => import("../MultiColumnSection/MultiColumnSection"),
  ),
  SlidingTestimonialsCarousel: dynamic(
    () => import("../SlidingTestimonialsCarousel/SlidingTestimonialsCarousel"),
  ),
  FAQAccordion: dynamic(() => import("../FAQAccordion/FAQAccordion")),
  VideoHighlight: dynamic(() => import("../VideoHighlight/VideoHighlight")),
  VideoGrid: dynamic(() => import("../VideoGrid/VideoGrid")),
  FeaturedArticles: dynamic(
    () => import("../FeaturedArticles/FeaturedArticles"),
  ),
  LogosBanner: dynamic(() => import("../LogosBanner/LogosBanner")),
  MeetFamilies: dynamic(() => import("../MeetFamilies/MeetFamilies")),
  CTAGrid: dynamic(() => import("../CTAGrid/CTAGrid")),
  CTABanner: dynamic(() => import("../CTABanner/CTABanner")),
  HeroWithForm: dynamic(() => import("../HeroWithForm/HeroWithForm")),
  PricingPlans: dynamic(() => import("../PricingPlans/PricingPlans")),
  heroTwoColumn: dynamic(() => import("./heroTwoColumn")),
  HeroWithButtons: dynamic(() => import("../HeroWithButtons/HeroWithButtons")),

  // non-modular components
  knowledgeIsPowerModule: dynamic(
    () => import("../expectantMotherKnowledgeIsPower"),
  ),
  meetProvidersModule: dynamic(() => import("../meetProviders")),
};

export default function PageBuilder({ props }: { props: ModularRefPageData }) {
  const { sections, colorway } = props;

  return (
    <>
      {!!sections &&
        sections.map((section) => {
          if (section._type && SECTIONS[section._type]) {
            const type = section._type;
            const Section = SECTIONS[type];

            return (
              <Section colorway={colorway} {...section} key={section.key} />
            );
          }
        })}
    </>
  );
}
